*{
    margin: 0;
    padding: 0;
}
.bg-img1 {
    background-image: url('../public/homebg.jpeg'); /* Update with the correct path to your image */
    background-size: cover;
    background-position: center;
  
    height: 100vh; /* Ensure the background covers the full viewport height */
  }
  .upload-area {
    margin-bottom: 20px;
  }
  
  .drag-drop-area {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .drag-drop-area p {
    margin: 0;
  }
  .upload-area {
    margin-bottom: 20px;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }
  
  .image-item {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-item .text-danger {
    position: absolute;
    top: 5px;
    right: 5px;
  }