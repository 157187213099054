.bg-img{
    width: 100%;
    height: 250px;
}

.titlegfh {
    color: white;
    font-size: 45px;
    text-align: center;
    padding: 10px 0 0 0;
    font-weight: bold;
    text-shadow: 2px 2px black;
    position: absolute;
    top: 40%;
    left: 43%;
  }

  .galleryImage1{
    width: 100%;
    height: 300px !important;
  }


  @media screen and (width<=799px) {
    /* .titlegfh {
        font-size: 30px !important;
        padding: 100px 0 0 0 !important;
      } */

      .R-gallery-bg{
        width: 100%;
        height: 140px !important;
      }
      .R-gallery-title{
        font-size: 30px !important;
        position: absolute;
        top: 32%;
        left: 37%;
      }
  }