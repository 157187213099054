.bg-img {
  width: 100%;
  height: 250px;
}

.btitle {
  color: white;
  font-size: 45px;
  text-align: center;
  padding: 10px 0 0 0;
  font-weight: bold;
  text-shadow: 2px 2px black;
  position: absolute;
  top: 40%;
  left: 44%;
}

.achivers-img {
  width: 100%;
  height: 300px;
}

.achivers-name{
  padding: 5px 8px;
font-weight: 600;
text-align: left;
}


@media screen and (width<=799px) {
  .R-btitle{
    font-size: 30px !important; 
    position: absolute;
    top: 30%;
    right: 5%;
  }

  .R-result-bg{
    width: 100%;
    height: 145px !important;
  }
}
