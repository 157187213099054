.first-banner-component {
  position: relative;
  top: -22px;
}

.home-banner {
  /* height: 50rem;
  width:fit-content; */
  height: 47.4rem;
  width: 100%;
}

.typed {
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
  animation: typing;
  animation-duration: 1.5s;
  animation-timing-function: steps(20, end);
  animation-fill-mode: forwards;
}

@keyframes typing {
  from {
    width: 30%;
  }

  to {
    width: 100%;
  }
}

.caption-display {
  position: absolute;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
  text-align: left;
  overflow: hidden;
}

.caption {
  color: white;
  font-size: 55px;
  font-weight: bold;
  text-shadow: 2px 2px black;
  text-align: left;
  padding: 0 40px;
}

.caption-display h5 {
  color: white;
  font-size: 45px;
  font-weight: bold;
  text-shadow: 2px 2px black;
}

.caption-display h6 {
  color: white;
  font-size: 35px;
  font-weight: bold;
  text-shadow: 2px 2px black;
}

.fixed-icon {
  border: 1px solid #004aad;
  float: right;
  position: fixed;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: 10px;
  right: 10px;
  background: white;
  border-radius: 5px;
  z-index: 9999;
}

.scrool-up-icon {
  border: 1px solid #004aad;
  position: fixed;
  bottom: 62px;
  right: 15px;
  background: white;
  border-radius: 5px;
  z-index: 9999;
  cursor: pointer;
}

.scrool {
  background: navy;
  color: white;
  width: 40px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.read-more{
  position: relative;
  left: 0px;
  bottom: 15px;
  color: #004aad;
  font-weight: bold;
}



/* UP COMING EVENTS  */

.card.events-crd {
  width: 100%;
  height: auto;
  border: 1px solid lightgray;
  background-color: white;
  color: #004aad;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgb(214, 212, 212);
  transition: transform 0.3s ease;
  /* Add a hover effect */
}

.events-crd-img {
  width: 100%;
  height: 300px;
  /* Adjusted height */
  object-fit: cover;
}

.upcoming-date {
  position: absolute;
  text-align: center;
  top: 10px;
  left: 10px;
  color: white;
  background: #004aad;
  border-radius: 50%;
  width: 81px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.carousel-container {
  padding: 20px 0;
  /* Adjust padding for carousel container */
}

.viewmoreevnt {
  background: #004aad;
  color: white;
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 18px;
}

.headdings {
  font-size: 45px;
  align-items: center;
  text-align: center;
  color: #004aad;
  font-family: system-ui;
  font-weight: 700;
}

.lines {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  display: flex;
}

.brochure-section {
  background-color: #004aad;
  /* Light grey background */
  border: 1px solid #e9ecef;
  /* Subtle border */
  border-radius: 10px;
  position: relative;
  /* Ensure position context for absolute elements */
  overflow: hidden;
  /* Prevent image overflow */
}

.brochure-section h4 {
  color: white;
  /* Primary color for emphasis */
}

.brochure-download-btn {
  background-color: white;
  border: none;
  color: #004aad;
  font-size: 1.2rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.brochure-download-btn:hover {
  background-color: #004aad;
  /* Darker shade on hover */
  text-decoration: none;
  border: 1px solid white;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin: auto;
  padding: auto;
}

.brochure-download-btn i {
  margin-right: 0.5rem;
  /* Space between icon and text */
}

.cross-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 170px;
  height: 140px;
  /* opacity: 0.5; */
  pointer-events: none;
  transform: rotateZ(10deg);
}

.cross-img1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 140px;
  height: 145px;
  /* opacity: 0.5; */
  pointer-events: none;
  transform: rotateZ(10deg);
}

.text-center {
  text-align: center;
}

.shake {
  animation: shake 2s ease infinite;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}


.sponsor-crd {
  width: 150px !important;
  height: 150px !important;
  border: 1px solid lightgray !important;
}

.sponsor-crd-img {
  width: 100%;
  height: 150px !important;
}

.testimonial-bg {
  /* background-image: url("../../public//Assets/testimonial-bg1.jpg"); */
  background-image: url("../../public/backd.avif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 380px;
  color: white;
}

.testimonial-crd {
  width: 100%;
  height: 250px !important;
  border-radius: 12px !important;
  background-color: white;
  padding: 12px 30px;
  box-shadow: none !important;
}

@media only screen and (max-width: 799px) {

  .fixed-icon {
    border: 1px solid #004aad;
    float: right;
    position: fixed;
    justify-content: flex-end;
    align-items: flex-end;
    bottom: 26px;
    right: 10px;
    background: white;
    border-radius: 5px;
    z-index: 9999;
  }

  .scrool-up-icon {
    border: 1px solid #004aad;
    position: fixed;
    bottom: 78px;
    right: 15px;
    background: white;
    border-radius: 5px;
    z-index: 9999;
    cursor: pointer;
  }

  .home-banner {
    height: 100%;
    width: 100%;
  }

  .caption {
    font-size: 35px;
    padding: 0;
  }

  .caption-display {
    position: absolute;
    top: 52%;
    left: 40%;
    transform: translate(-50%, -50%);
  }

  .first-banner-component {
    height: 275px;
  }

  .caption-display h2 {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px black;
    text-align: left;
  }

  .caption-display h5 {
    color: #ffffff;
    font-size: 25px !important;
    font-weight: bold;
    text-shadow: 2px 2px black;
    margin-top: 3px !important;
  }

  .caption-display h6 {
    color: white;
    font-size: 18px !important;
    font-weight: bold;
    text-shadow: 2px 2px black;
    margin-top: 3px !important;
  }

  .headdings {
    font-size: 35px;
    align-items: center;
    text-align: center;
  }

  /* -------- tendulkar surya --------- */

  .res-caption {
    font-size: 18px !important;
    height: 100px !important;
  }

  

  .testimonial-crd {
    height: auto !important;
    line-height: 1.2;
  }

  .brochure-section {
    margin-top: 10px !important;
  }

  .headdings {
    text-align: start;
  }


}

/* --------tendulkar surya--------- */

.home-banner {
  object-fit: cover;
}

@media screen and (width <= 425px) {
  .res-img-behind {
    display: none;
  }
}