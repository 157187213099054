.footer-logo {
  width: 300px;
  height: 8rem;
  background: transparent;
}

.social-icon {
  /* display: flex; */
  /* gap: 5px; */
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}
.s-img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: fill;
}
.icon-section {
  border: 1px solid #004aad;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: white;
  padding: 0;
  margin: 3px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

.footer-description p {
  color: white;
  padding: 0;
  margin: 5px 0;
}

li {
  list-style: none;
}

.footer-section {
  width: 100%;
  background: #000000;
  height: 230px;
  padding: 10px 20px;
}

.footer-title {
  color: white;
  text-align: left;
  padding: 0 30px;
  /* text-decoration: underline; */
}
.quick ul {
  padding: 5px 30px !important;
}
.quick li a {
  color: white;
  text-decoration: none;
}

.radio-buttons {
  display: flex;
  gap: 1rem; /* Space between the radio options */
  margin-top: 0.5rem;
}

.radio-buttons div {
  display: flex;
  align-items: center;
}


@media screen and (max-width: 799px) {
  .footer-section {
    height: auto;
  }
  .quick ul {
    padding: 5px 0px !important;
  }
  .footer-title {
    padding: 0 5px;
  }
  .footer-logo {
    width: 100%;
    height: 6rem;
    background: transparent;
  }
}

/* ------Responsive----- */

@media screen and (width<=799px) {
  .footer-section {
    height: auto;
  }
  .R-footer-m{
    margin-top: 5px !important;
    margin-bottom: 0px !important;
  }
}


@media screen and (max-width: 799px) {
  .footer-section {
    height: auto;
  }
  .quick ul {
    padding: 5px 0px !important;
  }
  .footer-title {
    padding: 0 5px;
  }
  .footer-sub{
    width: 80% !important;
  }
  .footer-sub-divs{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .footer-social-icon{
    display: flex;
    justify-content: start !important;
  }
  .R-margins{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}
