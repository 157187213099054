.bg-img {
  width: 100%;
  height: 250px;
}

.titlehj {
  color: white;
  font-size: 45px;
  text-align: center;
  padding: 10px 0 0 0;
  font-weight: bold;
  text-shadow: 2px 2px black;
  position: absolute;
  top: 40%;
  left: 39%;
}

/* Web view Start */

.contcat-banner-logo-section {
  display: flex;
  background: #004aad;
  color: white;
  height: 225px;
}

.contact-banner-logo {
  width: 100%;
  height: 225px;
}

.contact-cards-container {
  padding: 0 100px;
  display: flex;
  justify-content: center;
}

.address-title-section {
  display: flex;
  padding: 5px 10px;
  gap: 18px;
  align-items: flex-start;
}

.address-title-section h5 {
  color: #004aad;
  font-size: 17px;
}

.contact-cards {
  display: flex;
  gap: 100px;
  position: relative;
  bottom: 60px;
  text-align: left;
}

.contact-display {
  flex: 1;
}

.contact-form-section {
  display: flex;
  padding: 0px 100px;
  flex: 2;
  justify-content: space-between;
  gap: 30px;
}

.contact-first-title {
  font-size: 22px;
  font-weight: 500;
  padding: 20px 0 0 0;
}

.contact-main-title {
  font-weight: 500;
  padding: 30px 100px;
}

.banner-logo {
  width: 100%;
  height: 300px;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="file"],
input[type="password"],
select,
textarea {
  width: 100%;
  border: 0.3px solid #004aad;
  box-sizing: border-box;
  resize: vertical;
}

.send-button {
  padding: 8px 14px;
  margin: 20px 0 0 0;
  border: none;
  background: #004aad;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 6px;
  width: 100%;
}

.address-section {
  padding: 20px 70px;
  text-align: left;
}

.address-section h3 {
  color: #004aad;
  font-size: 20px;
}

.address-section h5 {
  font-size: 18px;
  line-height: 22px;
}

.contact-s-icon {
  display: flex;
  gap: 6px;
}

.FAQ__get__started__email {
  text-align: center;
  padding-bottom: 25px;
}

.FAQ__get__started__email > h3 {
  font-size: 18px;
  font-weight: 400;
  margin: 1rem 0;
}

.faq {
  text-align: center;
  font-size: 18px;
  background: rgb(245 245 245);
}

.faq h2 {
  font-size: 22px;
  font-weight: 500;
  padding: 21px 0 0 0;
}

.accordian {
  margin: 20px auto;
  width: 100%;
  max-width: 750px;
}

.accordian li {
  list-style: none;
  width: 100%;
  padding: 5px;
}

.accordian li label {
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2px;
  cursor: pointer;
  position: relative;
  background: #004aad !important;
  color: white;
}

.accordian li label::after {
  content: "+";
  font-size: 34px;
  position: absolute;
  right: 20px;
  transition: transform 0.5s;
}

input[type="radio"] {
  display: none;
}

.accordian .content {
  text-align: left;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s, padding 0.5s;
}

.accordian input[TYPE="radio"]:checked + label + .content {
  max-height: 600px;
  padding: 30px 20px;
}

.accordian input[TYPE="radio"]:checked + label::after {
  transform: rotate(135deg);
}
.error {
  color: red;
  margin: 0;
  padding: 0;
}
.contact-info-form-input {
  padding: 8px 12px;
  width: 95%;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(9, 9, 9, 0.3);
  border-radius: 2px;
}
.contact-info-form-input input {
  border: none;
  outline: none;
  border-left: 1px solid #ccc;
  margin-left: 10px;
}
.form-container {
  text-align: left;
  border: 1px solid lightgray;
  padding: 10px 30px;
}

.form-container form input {
  margin: 10px 0;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
}

.contact-info-form-input {
  border: 1px solid lightgray;
  background-color: white !important;
}

.contact-info-form-textarea {
  margin: 10px 0;
  padding: 18px;
  border-radius: 6px;
  height: 100px;
}

.enquiry-button {
  background: #004aad;
  color: white;
  border: none;
}
.connect-info-container {
  position: absolute;
  z-index: 2000;
  top: 310px;
  margin: 7px;
  right: 23px;
  background: #004aad;
  padding: 25px;
}
.vi_1 {
  border: 2px solid #dee2e6;
  outline-width: 0px;
  padding: 5px;
  border-radius: 5px;
  width: 450px;
}
.textarea {
  width: 100%;
  height: 5rem;
  padding: 10px;
}

.close-button {
  float: right;
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  position: absolute;
  left: 265px;
  top: 0px;
}

.contact-crd {
  width: 20rem;
  height: 125px;
  /* box-shadow: rgb(253, 100, 12) 1px -1px 6px 0px; */
  box-shadow: #004aad 0px 0px 5px 2px !important;
}
.contact-info-container-social-icon {
  display: flex;
  justify-content: center;
}

.c-icon-section {
  border: 1px solid #004aad;
  border-radius: 30px;
}

.contactright-img {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .form-container form input {
    width: 100% !important;
  }
  .form-container form textarea {
    width: 100% !important;
  }

  .contact-cards {
    display: block;
  }
  .contact-crd {
    width: 100% !important;
    margin: 8px 0 !important;
  }

  .contact-cards-container {
    margin: auto;
    padding: 0 12px;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .contact-info-form-input {
    background-color: #fbfff5;
  }

  .contact-info-form-input input {
    background-color: #fbfff5;
  }

  .contact-form-section {
    display: block;
    padding: 10px;
  }

  .contact-main-title {
    font-weight: 500;
    padding: 30px 10px;
  }
  .contact-banner-logo {
    height: 150px !important;
  }
  .contcat-banner-logo-section {
    height: 200px !important;
  }
}

@media screen and (max-width: 768px) {
  .contact-cards-container {
    padding: 0px 12px;
  }

  /* .contact-cards {
      gap: 26px;
      display: flex;
    } */

  .contact-crd {
    width: 15rem;
    height: 130px;
  }

  .contact-main-title {
    font-size: 22px;
    padding-top: 50px;
  }

  .contact-form-section {
    padding: 0px 5px;
  }
}

@media screen and (max-width: 992px) {
  /* .titlehj {
    font-size: 30px !important;
    padding: 100px 0 0 0 !important;
  } */
}

/* Mobile View End */
@media screen and (width<=992px) {
  .R-contactus-bg {
    width: 100%;
    height: 140px !important;
  }
  .R-contactus-title {
    font-size: 30px !important;
    position: absolute;
    top: 30%;
    left: 29%;
  }
  .R-contact-form {
    margin-bottom: 5px !important;
  }
  .R-contact-loc{
    margin-top: 5px !important;
  }
}
