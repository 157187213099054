.bg-img{
    width: 100%;
    height: 250px;
}.tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 20%;
  left: -55%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* Bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.fixed-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.title1 {
    color: white;
    font-size: 45px;
    text-align: center;
    padding: 10px 0 0 0;
    font-weight: bold;
    text-shadow: 2px 2px black;
    position: absolute;
    top: 35%;
    left: 35%;
  }

  .background-image-change{
    position: absolute;
    top: 0;
    z-index: -2;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 8px;
  }

  .zindex-text{
}
.image-back img{
      z-index: -14;
    width: 1348px;
    height: 880px ;

    border-radius: 4px;
  }


/* ------Responsive----- */

@media screen and (width<=799px){
    .R-key-bg{
        width: 100%;
        height: 135px !important;
    }
    .title1 {
        font-size: 30px !important;
        position: absolute;
        left: 20%;
        top: 28%;
      }
    .R-key-m{
        margin-top: 5px !important;
    }
}