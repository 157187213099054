.bg-img {
  width: 100%;
  height: 250px;
}

.title6 {
  color: white;
  font-size: 45px;
  text-align: center;
  padding: 10px 0 0 0;
  font-weight: bold;
  text-shadow: 2px 2px black;
  position: absolute;
  top: 35%;
  left: 42%;
}

.event-content-bg {
  background-image: url(../../public/backd.avif);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 10px 0px;
}

.upcomingbanner-img {
  width: 100%;
  height: 600px;
}

.title111 {
  color: #004aad;
  font-size: 45px;
  text-align: center;
  padding: 10px 0 0 0;
  font-weight: bold;
}

.event-detl {
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-detl b {
  min-width: 150px; /* Adjust based on your label length */
  text-align: left; /* Align labels to the right */
  padding-right: 10px; /* Space between label and value */
}

.event-detl span {
  flex: 1; /* Make sure the value spans the remaining width */
}

.event-detl p {
  margin: 0;
}
.eventimg {
  width: 100%;
  height: 265px;
}

.event-name {
  font-size: 32px;
  text-align: center;
  align-items: center;
  color: black;
  text-shadow: 2px 2px white;
}
.events-details {
  border: 1px solid #d1eed1;
  padding: 10px;
  background: linear-gradient(277deg, #004aad, white, white);
  border-radius: 20px;
}

.details {
  display: flex;
  flex-direction: column;
  /* gap: 10px;  */
  padding: 0px 10px;
}

.registr-btn {
  background-color: #004aad;
  color: white;
  padding: 10px 18px;
}

.registr-btn:hover {
  background-color: white;
  color: #004aad;
  padding: 10px 18px;
  border: 1px solid #004aad;
}

/* REGISTATION  */

.Registration-main-box {
  width: 100%;
  padding: 0 100px;
}
.Registration-box {
  border: 1px solid lightgray;
  /* box-shadow: 0px 1px 7px 5px #eeebeb; */
  padding: 0 30px;
  background-color: white;
}

.back-btn {
  padding: 5px 50px;
}

/* ------Responsive----- */

@media screen and (width<=799px) {
  .title6 {
    font-size: 30px !important;
    position: absolute;
    left: 34%;
    top: 30%;
  }
  .title111 {
    font-size: 30px !important;
    /* padding: 100px 0 0 0 !important; */
  }
  .R-event-bg {
    width: 100%;
    height: 140px !important;
  }

  .Registration-main-box {
    width: 100%;
    padding: 0;
  }
  .back-btn {
    padding: 5px 20px;
  }
  .upcomingbanner-img {
    height: auto !important;
  }
  .cross-img1 {
    position: absolute;
    bottom: 11px;
    left: 0;
    width: 83px;
    height: 100px;
  }
  .cross-img {
    position: absolute;
    bottom: 5%;
    right: -2%;
    width: 100px;
    height: 100px;
  }
}
