.bg-img {
  width: 100%;
  height: 250px;
}

.title {
  color: white;
  font-size: 45px;
  text-align: center;
  padding: 10px 0 0 0;
  font-weight: bold;
  text-shadow: 2px 2px black;
  position: absolute;
  top: 35%;
  left: 43%;
}


.image-card {
  border: none;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 280px;
}

.image-card:hover {
  /* transform: translateY(-10px); */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.mentr-img{
  width: 100%;
  border-radius: 20px;
  /* height: 325px ; */
}
.organizer-img {
  width: 200px;
  height: 200px;
  /* margin: auto; */
  /* border-radius: 50%;
  border: 4px solid #007bff;Different border color for organizers */
}
.person-img{
  margin: 75px auto 0px auto;
  height: 230px;
  width: 200px;
}

.oragnr-title{
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 20px;
  background-color: #004aad;
  border-radius: 20px;
  color: white;
  padding: 5px 0;
}
.organzr-img{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-text {
  font-size: 0.9rem;
  color: #6c757d;
}

.mentr-bg{
  background-image: url('../../public/Assets/bgg2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

/* ------------------- surya ------------- */

.background-image-change{
  position: absolute;
  top: 0;
  border-radius: 8px;
}

.image-back img{
  width: 1348px;
  height: 880px ;
  border-radius: 4px;
}
.image-back-blur{
  backdrop-filter: blur(2px) !important ;
  background-color: rgba(0, 0, 0, 0.667);
  border-radius: 8px;
}



/* ----Responsive---------- */
@media screen and (width<=799px) {
  .R-overview-bg {
  
    height: 125px !important;
  }
  .organzr-img{
    display: block;
    justify-content: center;
  }
  .R-overview-title {
    position: absolute;
    top: 25%;
    left: 30%;
    font-size: 32px;
  }
  .R-overview-mb {
    margin-bottom: 0px !important;
    margin-top: 5px !important;
  }
  .image-back-blur{
    backdrop-filter: blur(2px) !important ;
    background-color: rgba(0, 0, 0, 0.801);
    border-radius: 8px;
    margin: 0 !important ;
    padding: 10px !important;
  }
.image-back img{
  height: max-content;
  border-radius: 4px;
  display: none;
  object-fit: cover;

}
}
