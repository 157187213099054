.tail-text {
  font-size: 17px;
  color: black;
}

.navbar {
  background: #004aad;
  padding: 0;
  color: white;
}

.search-icon {
  display: flex;
  background-color: white;
  border: 1px solid white;
  padding: 2px 23px;
  border-radius: 5px;
  align-items: center;
}

.in-seaarch {
  padding: 10px 23px;
  color: black;
  font-size: 15px;
  outline: 0;
}

.grow {
  animation: grow 4s ease infinite;
}

@keyframes grow {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}


.fade-in-down {
  animation: fade-in-down 2s ease infinite;
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.join-whatsp {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: green;
  /* Button background color */
  border-radius: 5px;
  /* Rounded corners */
}

.join-whatsp:hover {
  background-color: #004aad;
  color: white;
}

.headers .nav-link {
  /* margin: 0px 20px; */
  margin: 0px 10px;
  /* color: black; */
  color: white;
  /* font-size: 20px; */
  font-size: 15px;
  font-weight: 600;
}

.headers .nav-link:hover {
  color: black;

  /* width: 100% !important; */
}
.nav-link.dropdown-item{
  margin-right: -10px;
}

.top-nav-header {
  width: 100%;
  /* height: 8rem; */
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
  background: #020203;
}

.top-nav {
  display: flex;
  float: left;
}

.navbar-left-content {
  display: flex;
  float: right;
  padding: 12px 0;
  color: white;
  gap: 20px;
  align-items: center;
  text-align: center;
}

.col-sm-12 {
  display: flex;
  gap: 12px;
}

.top-nav-icon {
  margin: 10px 0;
  cursor: pointer;
}

.top-nav-icon-content {
  padding: 10px 12px 0 0px;
}

.top-nav-icon-content p {
  margin: 0;
  padding: 0;
  text-align: right;
  text-align: right;
  font-size: 12px;
  line-height: 1.375;
}

.navbar-right-content {
  display: flex;
  padding: 5px 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  height: 7rem;
  width: 8rem;
  padding: 5px 0 !important;
  margin: 0px !important;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: white;
  left: -10px;
  font-size: 14px;
  font-weight: 600;
}

.dropdown {
  background-color: #004aad;
  padding: 0 !important;
}

.nav-logo-mbl {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
  padding: 0;
  margin: 0;
  text-align: center;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
}

.active-link {
  color: red;
  text-decoration: underline;
  /* Add any other styles for the active link here */
}

.pulse {
  animation: pulse 3s infinite ease-in-out alternate;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.2);
  }
}


.pulse1 {
  animation: pulse1 3s infinite ease-in-out alternate;
}

@keyframes pulse1 {
  from {
    transform: scale(0.85); /* Smaller scale at the start */
  }

  to {
    transform: scale(1); /* Slightly larger scale at the end */
  }
}


/* ----------- tendulkar surya ----------- */
.showonlymobile{
  display: none !important;
}



.hithere {
  animation: hithere 1s ease infinite;
}

@keyframes hithere {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 799px) {
  .top-nav-header {
    display: flex;
    flex-direction: column;
  }

  .left-header-res {
    display: flex;
    flex-direction: column;
  }

  .navbar-left-content {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .nav-logo-mbl {
    width: 100px;
    height: 40px;
    display: flex;
  }

  .navbar {
    border-bottom: 1px solid lightgray !important;
    background: none;
  }

  /* TESTIMONIAL  */
  .testimonial-bg {
    height: auto !important;
  }

  .testimonial-crd {
    height: auto !important;
    padding: 12px 5px;
  }


  /* --------tendulkar surya--------- */
  .top-nav-header{
    display: flex;
    justify-content: center !important;
  }
  .pulse{
    animation: none;
    object-fit: contain;
    width: 200px !important;
    height: 100px !important;
  }
  .navbar-left-content{
    display: block;
    text-align: left;
  }
  .showonlymobile{
    display: block !important;
  }
  .mobiledisnone{
    display: none !important;
  }
  .dropdown {
    background-color: #ffffff00;
    padding: 0 !important;
    color: black !important;
    text-align: center;
    text-transform: uppercase;
  }

  .dropdown-menu {
    text-align: center;
    border: none;
    padding: 0 10px !important;
  }
  .offcanvas-end{
    background: linear-gradient(135deg , rgb(255, 255, 255) , rgba(0, 0, 0, 0.762));
  }
  .dropdown-menu{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    background-color: rgba(255, 255, 255, 0.582);
    color: white !important;

    
  }
  .dropdown-item{
    text-align: start !important;
  }
  
 
 
} 
